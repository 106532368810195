import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/shared/components/SuspenseLoader';
import LessonMode from 'src/shared/components/LessonMode';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Transactions = Loader(
  lazy(() => import('src/modules/accounting/pages/transactions'))
);
const InternalTransactions = Loader(
  lazy(() => import('src/modules/accounting/pages/internal-transactions'))
);
const Students = Loader(
  lazy(() => import('src/modules/students/pages/stundents'))
);
const Profile = Loader(lazy(() => import('src/modules/profile/pages/profile')));
const GroupsRouter = Loader(lazy(() => import('src/modules/groups/router')));
const Groups = Loader(lazy(() => import('src/modules/groups/pages/groups')));
const GroupsDetail = Loader(
  lazy(() => import('src/modules/groups/pages/group-detail'))
);
const GroupAddLesson = Loader(
  lazy(() => import('src/modules/groups/pages/group-add-lesson'))
);
const GroupLessons = Loader(
  lazy(() => import('src/modules/groups/pages/group-lessons'))
);
const LessonDetail = Loader(
  lazy(() => import('src/modules/groups/pages/lesson-detail'))
);
const Stats = Loader(lazy(() => import('src/modules/accounting/pages/stats')));

const dashboardsRoutes = [
  {
    path: '/',
    element: <Navigate to="/dashboard/groups" replace />
  },
  {
    path: 'accounting/transactions',
    element: (
      <LessonMode>
        <Transactions />
      </LessonMode>
    )
  },
  {
    path: 'accounting/internal-transactions',
    element: (
      <LessonMode>
        <InternalTransactions />
      </LessonMode>
    )
  },
  {
    path: 'accounting/stats',
    element: (
      <LessonMode>
        <Stats />
      </LessonMode>
    )
  },
  {
    path: 'students',
    element: <Students />
  },
  {
    path: 'profile',
    element: <Profile />
  },
  {
    path: 'groups',
    element: <GroupsRouter />,
    children: [
      {
        path: '/',
        element: <Groups />
      },
      {
        path: ':id',
        element: <GroupsRouter />,
        children: [
          {
            path: '/',
            element: <GroupsDetail />
          },
          {
            path: 'new-lesson',
            element: <GroupAddLesson />
          },
          {
            path: 'lesson-detail',
            element: <GroupsRouter />,
            children: [
              {
                path: '/',
                element: <Navigate to="/dashboard/groups" replace />
              },
              {
                path: '/:lessonId',
                element: <LessonDetail />
              }
            ]
          },
          {
            path: '/lessons',
            element: <GroupsRouter />,
            children: [
              {
                path: '/',
                element: <Navigate to="/dashboard/groups" replace />
              },
              {
                path: '/:lessonId',
                element: <GroupLessons />
              }
            ]
          }
        ]
      }
    ]
  }
];

export default dashboardsRoutes;
