import { FC, ReactNode, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { UIContext } from 'src/shared/contexts/uiContext';

interface LessonModeProps {
  children: ReactNode;
}

const LessonMode: FC<LessonModeProps> = ({ children }) => {
  const { lessonMode } = useContext(UIContext);

  if (lessonMode) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

export default LessonMode;
