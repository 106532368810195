import type { ReactNode } from 'react';

import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupsIcon from '@mui/icons-material/Groups';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        name: 'Students',
        icon: PeopleAltIcon,
        link: '/dashboard/students'
      },
      {
        name: 'Groups',
        icon: GroupsIcon,
        link: '/dashboard/groups'
      }
    ]
  },
  {
    heading: 'Management',
    items: [
      {
        name: 'Accounting',
        icon: ReceiptTwoToneIcon,
        link: '/accent-sidebar/management/invoices',
        items: [
          {
            name: 'Transactions',
            link: '/dashboard/accounting/transactions'
          },
          {
            name: 'Internal transactions',
            link: '/dashboard/accounting/internal-transactions'
          },
          {
            name: 'Stats',
            link: '/dashboard/accounting/stats'
          }
        ]
      }
    ]
  }
];

export default menuItems;
