import { PartialRouteObject } from 'react-router';

import Authenticated from 'src/shared/components/Authenticated';
import Layout from 'src/shared/components/layouts';

import dashboardsRoutes from './dashboards';
import accountRoutes from './account';

const router: PartialRouteObject[] = [
  {
    path: '/',
    children: accountRoutes
  },
  {
    path: '/dashboard',
    element: (
      <Authenticated>
        <Layout />
      </Authenticated>
    ),
    children: [...dashboardsRoutes]
  }
];

export default router;
