import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import axios from 'src/shared/utils/axios';
import PropTypes from 'prop-types';

interface User {
  status: string;
  role: string;
  contract: false;
  programHistory: [];
  teacherHistory: [];
  createdAt: string;
  gender: string;
  _id: string;
  email: string;
  code: string;
  number: string;
  courseProgram: string;
  expectedPayment: number;
  name: string;
  surname: string;
  active: boolean;
  currentTeacher: string;
  __v: number;
  fullname: string;
  id: string;
  permissions: string[];
}
interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: any | null;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  logout: () => void;
  can: (type: string) => boolean;
  changeUserData: (user: User) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type changeUserData = {
  type: 'CHANGE_USER_DATA';
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | changeUserData;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (
  tokenObj: {
    token: string | null;
    previousExpiresIn?: string;
  } | null,
  type?: string
): void => {
  const expiresIn = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 3);
  if (tokenObj?.token) {
    localStorage.setItem(
      'token',
      JSON.stringify({
        token: tokenObj.token,
        expiresIn: type === 'login' ? expiresIn : tokenObj.previousExpiresIn
      })
    );
    axios.defaults.headers.common.Authorization = `Bearer ${tokenObj.token}`;
  } else {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  CHANGE_USER_DATA: (state: AuthState, action: changeUserData): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      user
    };
  }
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  can: () => true || false,
  changeUserData: (user: User) => {}
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const tokenObject = JSON.parse(window.localStorage.getItem('token'));
        const remainingTime =
          new Date(tokenObject.expiresIn).getTime() - new Date().getTime();

        if (tokenObject.token && remainingTime > 0) {
          setSession(
            {
              token: tokenObject.token,
              previousExpiresIn: tokenObject.expiresIn
            },
            'initialize'
          );

          const response = await axios.get<{ user: User; status: string }>(
            'api/v1/auth/get-user',
            {
              headers: {
                Authorization: `Bearer ${tokenObject.token}`
              }
            }
          );
          if (response.data.status === 'success') {
            const { user } = response.data;
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user
              }
            });
          } else {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null
              }
            });
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
          logout();
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const response = await axios.post<{ token: string; user: User }>(
      '/api/v1/auth/login',
      {
        email,
        password
      }
    );

    const { token, user } = response.data;

    setSession(
      {
        token: token
      },
      'login'
    );

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const resetPassword = async (email: string): Promise<void> => {
    const response = await axios.post<any>('api/v1/auth/forget-password', {
      email
    });
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const can = (type: string): boolean => {
    const access = state.user.permissions.includes(type);
    return access;
  };

  const changeUserData = (user: User) => {
    dispatch({
      type: 'CHANGE_USER_DATA',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        resetPassword,
        can,
        changeUserData
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
