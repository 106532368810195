import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Box, Card, Typography, Container, styled } from '@mui/material';

import Logo from 'src/shared/components/Logo';

import LoginForm from '../components/LoginForm';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  padding: 20px;
  justify-content: center;
`
);

function LoginBasic() {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Box sx={{ width: { sm: '546px', xs: '100%' } }}>
            <Card
              sx={{
                mt: 3,
                paddingLeft: { sm: '36px', xs: '22px' },
                paddingRight: { sm: '36px', xs: '22px' },
                paddingTop: { sm: '45px', xs: '22px' },
                paddingBottom: '27px'
              }}
            >
              <Box>
                <Box
                  sx={{
                    mb: 2,
                    img: {
                      width: '53px',
                      height: '38px'
                    }
                  }}
                >
                  <Logo />
                </Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Sign in')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
              <LoginForm />
            </Card>
          </Box>
        </TopWrapper>
      </MainContent>
    </>
  );
}

export default LoginBasic;
