import * as React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

import { UIContext } from 'src/shared/contexts/uiContext';

export default function SwitchesGroup() {
  const { lessonMode, setLessonMode } = React.useContext(UIContext);

  const handleChange = (event) => {
    setLessonMode(!lessonMode);
  };

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      sx={{ marginBottom: '5px' }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={lessonMode}
              onChange={handleChange}
              name="gilad"
              color="secondary"
            />
          }
          labelPlacement="start"
          label="Lesson Mode"
        />
      </FormGroup>
    </FormControl>
  );
}
