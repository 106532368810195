import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import 'nprogress/nprogress.css';

import App from 'src/App';
import * as serviceWorker from 'src/serviceWorker';
import 'src/shared/utils/chart';
import ScrollTop from 'src/shared/hooks/useScrollTop';
import { UIProvider } from 'src/shared/contexts/uiContext';
import store from 'src/shared/store';
import { SidebarProvider } from 'src/shared/contexts/SidebarContext';
import { AuthProvider } from 'src/shared/contexts/JWTAuthContext';

import { AlertProvider } from './shared/contexts/Alert';

const queryClient = new QueryClient();

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />
          <AuthProvider>
            <UIProvider>
              <AlertProvider>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </AlertProvider>
            </UIProvider>
          </AuthProvider>
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
